// DataPrivacy.tsx

import React from "react";
import { Container, Box, Typography, Link } from "@mui/material";

const DataPrivacy: React.FC = () => {
    return (
        <Container maxWidth="md">
            <Box my={4}>
                <Typography variant="h4" gutterBottom>
                    Datenschutzerklärung
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                    Gültig ab 14.01.2025
                </Typography>

                {/* 1. Einleitung */}
                <Typography variant="h6" gutterBottom>
                    1. Einleitung
                </Typography>
                <Typography paragraph>
                    Für die Melodi Ag mit Sitz in 8152 Glattbrugg hat der Datenschutz höchsten Stellenwert.
                    Nachfolgend finden Sie Informationen, wie wir Ihre personenbezogenen Daten bearbeiten, wenn Sie
                    unsere Dienstleistungen beziehen oder anderweitig mit uns in Verbindung stehen, mit uns
                    kommunizieren, unsere Webseite und die darauf angebotenen Onlineservices nutzen sowie sonst wie
                    mit uns zu tun haben.
                </Typography>

                {/* 2. Verantwortliche Stelle */}
                <Typography variant="h6" gutterBottom>
                    2. Verantwortliche Stelle
                </Typography>
                <Typography paragraph>
                    Verantwortlich für die Bearbeitung von personenbezogenen Daten ist:
                </Typography>
                <Typography paragraph component="div">
                    <strong>Melodi AG</strong><br />
                    Rohrstrasse 44<br />
                    8152 Glattbrugg<br />
                    E-Mail:&nbsp;
                    <Link href="mailto:info@melodi.ch">
                        info@melodi.ch
                    </Link>
                </Typography>
                <Typography paragraph>
                    Bei Fragen und/oder Begehren im Zusammenhang mit dem Schutz Ihrer persönlichen Daten können Sie
                    sich per E-Mail bei der vorgenannten Stelle melden.
                </Typography>

                {/* 3. Definitionen */}
                <Typography variant="h6" gutterBottom>
                    3. Definitionen
                </Typography>
                <Typography paragraph>
                    &quot;Personenbezogene Daten&quot; oder &quot;Personendaten&quot; sind Angaben, die sich auf eine
                    bestimmte oder bestimmbare natürliche Person (im Folgenden &quot;betroffene Person&quot;)
                    beziehen; eine &quot;betroffene Person&quot; ist eine natürliche Person, deren Personendaten
                    bearbeitet werden; &quot;bearbeiten&quot; bedeutet jeder Umgang mit Personendaten, wie
                    insbesondere die Speicherung von Daten.
                </Typography>
                <Typography paragraph>
                    Hierunter fallen verschiedene Kategorien von Angaben, die Sie uns mitteilen, wie etwa Name,
                    Kontaktdaten, E-Mail-Adresse, Geburtsdatum, IP-Adressen oder auch Vertragsdaten. Statistische
                    Daten oder anonymisierte Daten, die nicht direkt mit Ihrer Person in Verbindung gebracht werden
                    können, fallen nicht hierunter.
                </Typography>
                <Typography paragraph>
                    Diese Daten erhalten wir primär direkt von Ihnen als betroffene Person selbst oder von allfälligen
                    involvierten Dritten, wie bspw. Vertragsparteien, Behörden, Banken. Wenn Sie uns Daten über andere
                    Personen bekanntgeben, gehen wir davon aus, dass Sie dazu befugt sind und diese Daten korrekt sind
                    sowie dass Sie sichergestellt haben, dass diese Personen soweit nötig über diese Bekanntgabe
                    informiert sind und eingewilligt haben.
                </Typography>

                {/* 4. Datensicherheit */}
                <Typography variant="h6" gutterBottom>
                    4. Datensicherheit
                </Typography>
                <Typography paragraph>
                    Die Melodi AG arbeitet ausschliesslich mit zertifizierten Datencentern in der Schweiz, die den
                    aktuell geltenden technischen Standards entsprechen. Organisatorisch und technisch werden
                    Massnahmen getroffen um Ihre Daten gewissenhaft vor Verlust, Zerstörung, Verfälschung,
                    Manipulation oder unberechtigtem Zugriff zu schützen.
                </Typography>
                <Typography paragraph>
                    Unternehmensintern garantiert die Melodi AG den Datenschutz mittels Geheimhaltungserklärungen und
                    Verpflichtung zur Einhaltung der datenschutzrechtlichen Bestimmungen.
                </Typography>
                <Typography paragraph>
                    Trotzdem weisen wir Sie darauf hin, dass eine Kommunikation mit uns über das öffentliche, frei
                    zugängliche Internet mit gewissen Sicherheitsrisiken verbunden ist, auf welche wir keinen Einfluss
                    und keine Kontrollmöglichkeiten haben. Melden Sie sich bei uns, wenn Sie die Kommunikation über
                    eine gesicherte Verbindung wünschen.
                </Typography>

                {/* 5. Rechtsgrundlagen und Zwecke der Datenerfassung-/bearbeitung */}
                <Typography variant="h6" gutterBottom>
                    5. Rechtsgrundlagen und Zwecke der Datenerfassung-/bearbeitung
                </Typography>
                <Typography paragraph>
                    Melodi AG bearbeitet Ihre personenbezogenen Daten primär zur Aufnahme bzw. zur Abwicklung von
                    unseren Verträgen mit Ihnen und unseren Geschäftspartnern. Weiter bearbeiten wir Ihre Personendaten
                    zur Erfüllung von uns auferlegten, gesetzlichen Pflichten und/oder gestützt auf Ihre Einwilligung
                    (etwa Kontaktaufnahme via Kontaktformular, Newsletter).
                </Typography>
                <Typography paragraph>
                    Darüber hinaus bearbeiten wir Ihre Personendaten und diejenigen von weiteren Personen, soweit dies
                    zulässig und uns als angezeigt erscheint, auch zu nachfolgenden Zwecken, an denen wir ein
                    entsprechendes berechtigtes Interesse haben:
                </Typography>
                <ul>
                    <li>
                        Zu Marketingzwecken und zur Beziehungspflege sowie zur Verbesserung unserer Dienstleistungen
                        und unseres Betriebs (inkl. Durchführung von Anlässen), soweit Sie der Nutzung Ihrer Daten
                        nicht widersprochen haben.
                    </li>
                    <li>
                        Die IP-Adresse in Kombination mit Datum und Zeitpunkt des Zugriffs, die angeforderten Daten,
                        der verwendete Browser und das eingesetzte Betriebssystem werden ausschliesslich bearbeitet,
                        um Sicherheitsverstösse und Hackerangriffe zu erkennen sowie die korrekte Funktionsweise der
                        Onlineservices zu gewährleisten.
                    </li>
                    <li>Gewährleistung unseres Betriebs, insbesondere der IT und unserer Website.</li>
                    <li>Zu Sicherheitszwecken und für Zugangskontrollen.</li>
                    <li>Zur Einhaltung von Gesetzen, Weisungen und Empfehlungen von Behörden und interner Regularien.</li>
                    <li>Zu Zwecken unseres Risikomanagements im Rahmen unserer Unternehmensführung.</li>
                </ul>

                {/* 6. Bewerbungen */}
                <Typography variant="h6" gutterBottom>
                    6. Bewerbungen
                </Typography>
                <Typography paragraph>
                    Wir veröffentlichen unregelmässig Stellenanzeigen (bspw. auf unserer Webseite, auf entsprechenden
                    Portalen etc.). Sie haben die Möglichkeit, uns Ihre Bewerbung entweder postalisch oder elektronisch,
                    etwa über unsere Webseite zu senden. Bei diesen (digitalen) Bewerbungen und auch im Falle von
                    Spontanbewerbungen, werden Ihre Bewerber- und Bewerbungsdaten von uns zur Abwicklung des
                    Bewerbungsverfahrens (elektronisch) gespeichert und bearbeitet.
                </Typography>
                <Typography paragraph>
                    Sofern nach dem Bewerbungsverfahren ein Arbeitsvertrag geschlossen wird, speichern wir die bei der
                    Bewerbung übermittelten Daten in Ihrer Personalakte zum Zweck des üblichen Organisations- und
                    Verwaltungsprozesses – dies unter Beachtung der weitergehenden rechtlichen Verpflichtungen.
                </Typography>
                <Typography paragraph>
                    Bei der Zurückweisung einer Bewerbung löschen wir die uns übermittelten Daten innerhalb von 24
                    Monaten nach Abschluss des Rekrutierungsprozesses. Die Löschung erfolgt ausnahmsweise dann nicht,
                    wenn die Daten aufgrund gesetzlicher Bestimmungen zwingend länger gespeichert werden müssen.
                </Typography>
                <Typography paragraph>
                    Sofern Sie ausdrücklich in eine längere Speicherung Ihrer Daten einwilligen, bspw. für Ihre
                    Aufnahme in unsere Bewerberdatenbank, werden die Daten aufgrund Ihrer Einwilligung
                    weiterverarbeitet. Ihre Einwilligung können Sie jederzeit durch Erklärung uns gegenüber mit
                    Wirkung für die Zukunft widerrufen. Eine entsprechende Meldung an die hiervor genannte
                    verantwortliche Stelle unter Ziffer 2 genügt dafür. Die bis zu diesem Zeitpunkt erfolgten
                    Bearbeitungsvorgänge sind von Ihrem Widerruf jedoch nicht betroffen.
                </Typography>

                {/* 7. Kontaktaufnahme */}
                <Typography variant="h6" gutterBottom>
                    7. Kontaktaufnahme
                </Typography>
                <Typography paragraph>
                    Mit der Eingabe Ihrer Daten auf www.melodi.ch, resp. durch die Kontaktaufnahme per Mail oder Telefon
                    willigen Sie ein, dass Ihre angegebenen, persönlichen Daten für die dienstleistungsbezogene Nutzung
                    verwendet werden darf.
                </Typography>
                <Typography paragraph>
                    Bei der Kontaktaufnahme mit Melodi AG (telefonisch, per E-Mail, Kontaktformular oder via der
                    Onlinebuchungs-plattform) werden die mitgeteilten Angaben zur Bearbeitung einer Anfrage und deren
                    Abwicklung verarbeitet und bei Bedarf in ein Customer-Relationship-Management-System (CRM)
                    gespeichert. Melodi AG verwendet ein eigenentwickeltes CRM zur effizienten Bearbeitung der
                    Nutzeranfragen und um die Qualität der Anfragen sicherzustellen.
                </Typography>

                {/* 8. Newsletter */}
                <Typography variant="h6" gutterBottom>
                    8. Newsletter
                </Typography>
                <Typography paragraph>
                    Melodi AG verschickt in regelmässigen Abständen Newsletter per E-Mail und informiert Interessenten
                    über fachbezogenen Informationen (wie technische Neuigkeiten, Softwareupdates,
                    Sicherheitserläuterungen, medizinische Updates, u.a.).
                </Typography>
                <Typography paragraph>
                    Die Nutzer müssen dem Newsletter aktiv zustimmen und sind in der Lage ihn durch die Kontaktaufnahme
                    mit Melodi AG oder innerhalb eines Newsletters selbst mittels dem &quot;Newsletter abbestellen&quot;
                    - Link abzubestellen.
                </Typography>
                <Typography paragraph>
                    Mit der Zustimmung zum Newsletter willigt der Nutzer ein, dass personenbezogene Daten für die
                    Abwicklung der Newsletter verwendet werden dürfen. Eine darüberhinausgehende Bearbeitung Ihrer
                    Personendaten findet nicht statt, sofern Sie nicht Ihr Einverständnis dazu gegeben haben.
                </Typography>
                <Typography paragraph>
                    <strong>Mailchimp:</strong> Für die Abwicklung der Newsletter setzt Melodi AG auf den Drittanbieter{" "}
                    <Link
                        href="https://mailchimp.com"
                        target="_blank"
                        rel="noopener"
                    >
                        Mailchimp
                    </Link>. Melodi AG achtet darauf, nur die für die Abwicklung der Newsletter relevanten Daten innerhalb
                    von Mailchimp zu speichern. Es gelten dabei die Datenschutzbestimmungen von Mailchimp:{" "}
                    <Link
                        href="https://mailchimp.com/legal/data-processing-addendum/"
                        target="_blank"
                        rel="noopener"
                    >
                        https://mailchimp.com/legal/data-processing-addendum/
                    </Link>.
                </Typography>

                {/* 9. Datenweitergabe sowie Datenübermittlung ins Ausland */}
                <Typography variant="h6" gutterBottom>
                    9. Datenweitergabe sowie Datenübermittlung ins Ausland
                </Typography>
                <Typography paragraph>
                    Eine Weitergabe personenbezogener Daten erfolgt nur mit ausdrücklicher Einwilligung, bei
                    gesetzlicher Verpflichtung, oder um vertraglich zugesicherte Dienstleistungen erbringen zu können.
                </Typography>
                <Typography paragraph>
                    Im Rahmen der hievor genannten Bearbeitungszwecke gemäss Ziffer 5 können wir Ihre personenbezogenen
                    Daten auch an Dritte bekannt geben, soweit dies erlaubt ist oder uns als angezeigt erscheint. Diese
                    bearbeiten Ihre Daten entweder in unserem Auftrag, in gemeinsamer oder in eigener
                    Verantwortlichkeit. Dabei übermitteln wir Ihre Personendaten insbesondere an die nachfolgend
                    aufgelisteten Empfänger. Soweit wir hierzu verpflichtet sind, stellen wir sicher, dass die
                    Empfänger Ihre Daten vertraulich und nach den geltenden gesetzlichen Bestimmungen behandeln und
                    Ihre Daten nur zum Zweck des jeweiligen Services genutzt werden.
                </Typography>
                <Typography paragraph component="div">
                    <strong>Dienstleister, einschliesslich Auftragsbearbeiter:</strong> Inkassobüro, Treuhänder, Banken,
                    Versicherungen, Marketing-, Vertriebs- oder Druckdienstleistungen, Versanddienstleister,
                    Werbedienstleister, die für uns Analyse- und Marketing betreiben; <br />
                    <strong>Behörden:</strong> Ämter, Gerichte oder andere Behörden.
                </Typography>
                <Typography paragraph>
                    Die Empfänger der Daten befinden sich ausschliesslich in der Schweiz, d.h. es findet keine
                    Datenübermittlung ins Ausland statt. Ausgenommen ist die Verarbeitung des Newsletters via
                    Mailchimp wie unter Ziffer 8 beschrieben ist. Es handelt sich dabei nur um die E-Mail-Adresse.
                </Typography>

                {/* 10. Cookies / Tracking */}
                <Typography variant="h6" gutterBottom>
                    10. Cookies / Tracking und andere Technologien im Zusammenhang mit der Nutzung unserer Webseite
                </Typography>
                <Typography paragraph>
                    <strong>Cookies:</strong> Cookies sind Daten, die eine Webseite auf Ihrem Computer
                    zwischenspeichert. Sie dienen i.d.R. dazu Ihr Nutzererlebnis zu vereinfachen, z.B. mittels
                    Speicherung von Sitzungen und Passwörter, um ein bestehendes Login aufrechtzuerhalten. Ein Bezug
                    zu personenbezogenen Daten ist nicht gegeben. Die erfassten Cookies Ihres Browsers können
                    selbständig in den Browsereinstellungen deaktiviert werden, damit riskieren Sie jedoch nicht alle
                    Funktionen der Webseiten nutzen zu können. Der Besuch der Webseite fordert das Akzeptieren der
                    Cookies nicht.
                </Typography>
                <Typography paragraph>
                    <strong>Tracking:</strong> Melodi AG nutzt keine Tracking-Tools.
                </Typography>

                {/* 11. Einbindung von Diensten und Inhalten Dritter (Framing) */}
                <Typography variant="h6" gutterBottom>
                    11. Einbindung von Diensten und Inhalten Dritter (sog. Framing)
                </Typography>
                <Typography paragraph>
                    Melodi AG setzt innerhalb der Webseiten Inhalts- oder Serviceangebote von Drittanbietern ein, die
                    analog zu unseren Webservern ihre Serverprotokolle auswerten können und für die keine Verantwortung
                    übernommen werden.
                </Typography>
                <Typography paragraph>
                    Melodi AG nutzt auf der Webseite das sog. Framing des Netzwerkes Youtube und Vimeo. Das Abspielen
                    von Videos ruft ein Datenstream der Server von Youtube oder Vimeo zur Folge. Dabei nutzen wir den
                    sogenannten «Erweiterten Datenschutzmodus». Erst beim Abspielen des Videos wird ein Cookie auf
                    Ihrem Rechner gespeichert. Möchten Sie sichergehen, dass keine Daten von Ihnen bei Youtube oder
                    Vimeo gespeichert werden, so klicken Sie die eingebetteten Videos nicht an. Für die damit
                    verbundene Verwendung von Daten Ihres Browsers bzw. Endgerätes verweist Melodi AG auf die
                    Datenschutzeinstellungen von Youtube{" "}
                    <Link
                        href="https://www.youtube.com/intl/de_be/howyoutubeworks/user-settings/privacy"
                        target="_blank"
                        rel="noopener"
                    >
                        https://www.youtube.com/intl/de_be/howyoutubeworks/user-settings/privacy
                    </Link>{" "}
                    und auf die Datenschutzhinweise von Vimeo:{" "}
                    <Link
                        href="https://vimeo.com/privacy"
                        target="_blank"
                        rel="noopener"
                    >
                        https://vimeo.com/privacy
                    </Link>.
                </Typography>

                {/* 12. Haftung für Links */}
                <Typography variant="h6" gutterBottom>
                    12. Haftung für Links
                </Typography>
                <Typography paragraph>
                    Auf die aktuelle und zukünftige Gestaltung und die Inhalte der verlinkten/verknüpften Seiten auf
                    unserer Webseite haben wir keinerlei Einfluss. Diese Feststellung gilt für alle innerhalb des
                    eigenen Internetangebotes gesetzten Links und Verweise. Für illegale, fehlerhafte oder
                    unvollständige Inhalte und insbesondere für Schäden, die aus der Nutzung oder Nichtnutzung solcher
                    Informationen entstehen, haftet allein der Anbieter der Seite, auf welche verwiesen wurde, nicht
                    derjenige, der über Links auf die jeweilige Veröffentlichung lediglich verweist. Wir schliessen im
                    Rahmen des gesetzlich Zulässigen jede Haftung aus.
                </Typography>

                {/* 13. Dauer der Aufbewahrung */}
                <Typography variant="h6" gutterBottom>
                    13. Dauer der Aufbewahrung
                </Typography>
                <Typography paragraph>
                    Wir bearbeiten und bewahren Ihre Personendaten so lange auf, wie dies zur Erfüllung unserer
                    vertraglichen und gesetzlichen Pflichten oder zur Einhaltung der jeweiligen gesetzlichen
                    Bestimmungen notwendig oder angemessen ist oder solange sie für die Zwecke, für die sie gesammelt
                    wurden, notwendig sind. Soweit möglich, anonymisieren oder löschen wir Ihre Personendaten
                    grundsätzlich, sobald diese nicht mehr benötigt werden, resp. spätestens nach Ablauf der
                    gesetzlich vorgeschriebenen Aufbewahrungsfrist (in der Regel nach 10 Jahren).
                </Typography>
                <Typography paragraph>
                    Sie können die Löschung Ihrer personenbezogenen Daten jederzeit selbst beantragen, indem Sie uns
                    ein entsprechendes Begehren, an die hiervor genannte verantwortliche Stelle unter Ziffer 2 zukommen
                    lassen. Wir werden Ihrem Begehren nachkommen, sofern wir nicht aus anderen Gründen (z.B. der
                    gesetzlichen Aufbewahrungspflicht) zur weiteren Aufbewahrung verpflichtet sind.
                </Typography>

                {/* 14. Rechte der betroffenen Personen */}
                <Typography variant="h6" gutterBottom>
                    14. Rechte der betroffenen Personen
                </Typography>
                <Typography paragraph>
                    Sie haben grundsätzlich das Recht, bei uns jederzeit Auskunft darüber zu erhalten, ob wir
                    Personendaten über Sie bearbeiten. Weiter haben Sie die Möglichkeit, Ihre personenbezogenen Daten
                    durch uns berichtigen, sperren oder löschen zu lassen. Davon ausgenommen sind Daten, die wir zur
                    Abwicklung noch ausstehender Aufträge oder zur Durchsetzung bestehender Rechte und Ansprüche
                    benötigen, sowie Daten, die wir aufgrund gesetzlicher Bestimmungen aufbewahren müssen. Sie können
                    auch gegen die Bekanntgabe bestimmter Personendaten Widerspruch einlegen. Zudem können Sie die an
                    uns übergebenen Daten wieder von uns herausverlangen.
                </Typography>
                <Typography paragraph>
                    Sie können jederzeit Ihre einmal erteile Einwilligung in die Nutzung Ihrer personenbezogenen Daten
                    widerrufen. Ihr Widerruf beschränkt sich jedoch auf die Speicherung und Nutzung personenbezogener
                    Daten, die wir nicht aufgrund gesetzlicher Erlaubnistatbestände auch ohne Ihre Einwilligung
                    speichern oder nutzen dürfen. Ihr Widerruf hat ausserdem keine Auswirkung auf die bisherigen
                    Bearbeitungsvorgänge, welche aufgrund Ihrer Einwilligung getätigt wurden.
                </Typography>
                <Typography paragraph>
                    Zur Geltendmachung Ihrer Rechte wenden Sie sich bitte schriftlich und unter Nachweis Ihrer
                    Identität an uns (an die hiervor genannte verantwortliche Stelle unter Kontaktdaten Ziffer 2).
                    Soweit Ihre Rechte nicht aufgrund gesetzlicher Vorschriften oder überwiegender öffentlicher oder
                    privater Interessen eingeschränkt sind, werden wir Ihrem Anliegen gerne nachkommen. Als
                    Aufsichtsbehörde in Datenschutzangelegenheiten fungiert der Eidgenössische Datenschutz- und
                    Öffentlichkeitsbeauftragte (
                    <Link
                        href="https://www.edoeb.admin.ch/edoeb/de/home.html"
                        target="_blank"
                        rel="noopener"
                    >
                        https://www.edoeb.admin.ch/edoeb/de/home.html
                    </Link>).
                </Typography>

                {/* 15. Änderungen */}
                <Typography variant="h6" gutterBottom>
                    15. Änderungen
                </Typography>
                <Typography paragraph>
                    Wir behalten uns das Recht vor, diese Datenschutzerklärung jederzeit zu ändern. Es gilt die
                    jeweils aktuelle, auf unserer Webseite publizierte Fassung bzw. diejenige Fassung, welche wir
                    Ihnen gegebenenfalls anderweitig abgegeben haben.
                </Typography>
                <Typography paragraph>
                    Sofern Sie von der Änderung der Datenschutzerklärung betroffen sind (beispielsweise, wenn Sie
                    einen Newsletter-Dienst nutzen), werden wir Sie über eine solche Änderung in geeigneter Form
                    informieren. Die Datenschutzerklärung begründet kein vertragliches oder sonstiges formales
                    Rechtsverhältnis gegenüber oder im Auftrag einer Partei.
                </Typography>
            </Box>
        </Container>
    );
};

export default DataPrivacy;
