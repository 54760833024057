// App.tsx
import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import HomePage from "./pages/index";
import Login from "./pages/auth/login";
import ProtectedRoute from './components/ProtectedRoute';
import ForgotPassword from "./pages/auth/forgot-password";
import ResetPassword from "./pages/auth/reset-password";
import DataPrivacy from "./pages/data-privacy/DataPrivacy";

const App: React.FC = () => {
    return (
        <Router>
            <Routes>
                <Route path="/"
                       element={
                           <ProtectedRoute>
                               <HomePage/>
                           </ProtectedRoute>
                       }/>
                <Route path="/login"
                       element={
                           <Login/>
                       }/>
                <Route path="/auth/forgot-password"
                       element={
                           <ForgotPassword/>
                       }/>
                <Route path="/auth/reset-password"
                       element={
                           <ResetPassword/>
                       }/>
                <Route path="/data-privacy" element={<DataPrivacy/>} />

            </Routes>
        </Router>
    );
};

export default App;
