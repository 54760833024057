export const paths = {
    index: '/',
    auth: {
        login: '/auth/login',
        confirm: '/auth/confirm',
        forgotPassword: '/auth/forgot-password',
        resetPassword: '/auth/reset-password',
    },
    notAuthorized: '/401',
    notFound: '/404',
    serverError: '/500',
    dataPrivacy: '/data-privacy'
};
